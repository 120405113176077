import React, { useState } from 'react'
import './ImagesList.css'
import ImageList from './ImageList';

const ImagesList = ({lista, onSelected, layout}) => {
  const [selected, setSelected] = useState("")

  const handleSelected = (ev) => {
    setSelected(ev.target.src)
    if (!onSelected) return false;
    onSelected(ev.target.src);
  }

  const clase = layout==='vertical' ? "imageslist-ver w-15" : "imageslist-hor w-15"

  if (!lista?.length) return <></>;

  return (
    <div className={clase}>
      {lista.map((img, i)=>{        
        const src = img.url ? img.url:img;
        return <ImageList key={i} image={src} onClick={handleSelected} selected={selected} />
      })}
    </div>
  )
}

export default ImagesList 
