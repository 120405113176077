import React from 'react'
import './Payment.css'

const Payment = ({data, selected, onSelect}) => {
  const clases = (selected && selected.id==data.id) ? 'payment-order selected' : 'payment-order'
  const hdlSelect = () => {
    // console.log("select",data);
    onSelect(data)
  }

  return (
    <div className={clases} onClick={hdlSelect}>
        <p className='bold'>{data.valor.nombre}</p>
        <p>{data.valor.descripcion}</p>
    </div>
  )
}

export default Payment
