import React from 'react' 
import './NewsLetter.css'

const NewsLetter = () => {
  return (
    <div className='news-letter'>
      <h1>Recibe las mejores promociones en tu correo</h1>
      <p>Suscríbete y mantente al día</p>
      <div>
        <input type="email"  placeholder='Your email address'/>
        <button>Subscribir</button>
      </div>
    </div>
  )
}

export default NewsLetter
