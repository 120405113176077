import React, { useState } from 'react'
import './OrderInfo.css'
import OrderHeader from './OrderHeader'
import OrderDetail from './OrderDetail'
import OrderAdmin from './OrderAdmin'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { AuthRequest } from '../../Apis/Axios'
import { getLocal, asLocal } from '../../Apis/Api'
import { asMoney } from '../Order/Totales/Total'

const OrderInfo = ({staff}) => {
  const {orderId} = useParams();
  const tienda = getLocal('tienda')
  const [pedido, setPedido] = useState(null)

  const fetch_order = async () => {
    const res = AuthRequest()
    const url = `pedido/${tienda}/${orderId}`
    const r = await res.fetch(url)
    if (r.ok) {
      setPedido(r.data)        
    }

  }

  useEffect(()=>{
    fetch_order()
  },[])


  const hdlRefresh = async () => await fetch_order();

  if (!pedido) return <></>

  return (
    <div className='orderinfo rowbox'>      
      <div className="row">
        <OrderHeader data={pedido} />
      </div>
      <div className="row">
        <OrderDetail data={pedido} />      
      </div>
      <div className="row orderinfo-obs">
        {pedido.observaciones}
      </div>
      <div className="orderinfo-total row">
        <div className="w-40">
        {
            pedido.logs.map((e,i)=>{
              if (!staff && !e.publico ) return null;
              return (
                <div className="row" key={i}>
                  <div className="w-80 bold " >{asLocal(e.created_at)} by {e.usuario} </div>
                  <div className="w-80 " >{e.acciones}</div>
                </div>
              )
            })
          }
        </div>
        <div className="w-10"></div>
        <div className="w-50">
          {
            pedido.totales.map((e,i)=>{
              return (
                <div className="row" key={i}>
                  <div className="w-60 bold" >{e.label}</div>
                  <div className="w-40 bold flex-right" key={i} >$ {asMoney(e.total)}</div>
                </div>
              )

            })
          }
        </div>
      </div>
      {
        staff ? <OrderAdmin onRefresh={hdlRefresh} data={pedido} /> : <></>
      }
    </div>
  )
}

export default OrderInfo
