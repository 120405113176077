import React from 'react'
import Item from './Item'
import './ItemList.css'
import useShop from '../../../Hooks/useShop'

const ItemList = () => {
    const {cartItems} =useShop()
  return (
    <div className='itemlist row'>
      {
        cartItems.map((e,i) => {
            return <Item data={e} key={i} />
        })
      }
    </div>
  )
}

export default ItemList
