import React, { useState, useEffect } from 'react'
import './ShippingToList.css'
import ShippingTo from './ShippingTo';
import { getLocal, saveLocal } from '../../../Apis/Api';
import Address from '../../AddressList/Address';
import useAuth from '../../../Hooks/useAuth';
import { AuthRequest } from '../../../Apis/Axios';
import useShop from '../../../Hooks/useShop';

const ShippingToList = () => {
  const {getAuth} = useAuth()
  const {addSetting} = useShop()
  const [lista, setLista] = useState([])
  const [selected, setSelected] = useState(getLocal('address'))
  const [agregar, setAgregar] = useState(false)
  
  const fetch_data = async () => {
    const r = AuthRequest()
    const res = await r.fetch('usuario/direccion')
    if (res.ok) {
        setLista(res.data.rows)
    } else  setLista(...[])
  }
  
  const hdlSelect = async (choice) => {
    setSelected(choice)
    saveLocal('address', choice)
    addSetting('cp',choice.cp)
  }

  const hdlCancel = () => setAgregar(false);
  const hdlAdd = () => setAgregar(true);
  const hdlRefresh = () => {
    setAgregar(false);
    fetch_data()
  }
  
  useEffect(()=>{
    fetch_data();
    let current=null;
    for (let i = 0; i < lista.length; i++) {
      const element = lista[i];
      if (selected?.id===element.id) {
        saveLocal("address", element)
        addSetting('cp', Number(element.cp))
        setSelected(element)        
      }
      if (element.actual) current = element;
    }
    if (!selected && current) {
      saveLocal("address",current)
      addSetting('cp', Number(current.cp))
      setSelected(current)        
    }
  },[]);  

  const show = () => {
    if (!agregar) return <></>
    return (
      <div className="row">
        <div className="col-12">
          <Address onCancel={hdlCancel} onUpdate={hdlRefresh} me={getAuth()?.user} />
        </div>
      </div>
    )
  }
  
  return (
    <div className='shippingtolist row'>
      <h1>Entregar en:</h1><button onClick={hdlAdd} className='btn-lg'>Nueva</button>
      {
        agregar ? show() : <></>
      }
      <div className='shippingtolist-container'>

        {lista.map((e,i)=>{
            return <ShippingTo data={e} selected={selected} key={i} onSelect={hdlSelect} />
        })
        }
      </div>
    </div>
  )
}

export default ShippingToList 
