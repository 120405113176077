import React, { createContext, useState } from 'react'
import { saveLocal, getLocal, deleteLocal } from '../Apis/Api';

export const ShopContext = createContext(null);

const ShopContextProvider = (props)=>{
    const setting_data = {}

      /* ---------- Cart Operations -------------- */

    const locateItem = (p) => {
        let  idx=0;
        for (const i of cartItems) {
            if (i.id === p.id && i.stock_id === p.stock_id && i.custom === p.custom) return idx;
            idx++;
        }
        return -1;
    }
    
    const getTotalCartAmount = ()=> {
        let totalAmount = 0;
        for (const item of cartItems) {            
            totalAmount+=item.cantidad * item.oferta;
        }
        return totalAmount;
    };

    const getTotalCartItems = ()=> {
        let totalItems = 0;
        for (const item of cartItems) {
            totalItems+=item.cantidad;
        }
        return totalItems;
    };

    const getTotalWeight = ()=> {
        let weight = 0;
        for (const item of cartItems) {
            weight+=item.cantidad * (item.peso>item.volumen ? item.peso : item.volumen);
        }
        return weight;
    };

    const getShippingAmount = (data) => {
        let amount=0;
        if (!data || !data.valor) return 0;
        const w = getTotalWeight()
        const t = getTotalCartAmount()
        if (data.valor.gratis<=0 || data.valor.gratis>=t) {
            amount=data.valor.precio;
            if (w>data.valor.hasta) 
                amount+= (w-data.valor.hasta)/data.valor.adicional.ampara*data.valor.adicional.precio;
        }
        return amount
    }

    const updateCart = (nuevo) => {
        addSetting('currentSize','')    
        setCartItems([...nuevo])
        saveLocal('cart', nuevo)
    }

    const addToCart = (p, custom)=>{
        if (custom) p.custom=custom
        if (p.oferta<=0) p.oferta=p.precio
        const idx = locateItem(p)
        let nuevo = [...cartItems]
        if (idx>=0) {
            nuevo[idx].cantidad++;
        } else {
            p.cantidad=1
            nuevo.push(p)
        }
        updateCart(nuevo)
    };

    const removeFromCart = (row)=>{
        const idx = locateItem(row)
        let nuevo = [...cartItems]
        if (idx>=0)  {
            nuevo[idx].cantidad--;
            if (nuevo[idx].cantidad<=0) nuevo.splice(idx, 1);
            updateCart(nuevo)
        }
    };
    const getLocalCart = ()=> {
        const cart = getLocal('cart');
        const auth = getLocal('auth');
        if (!cart) 
            if (!auth?.x_token) return []

        return cart
    }
    
    /* ---------- Setting Operations -------------- */
    const getSetting = (key) => setting_data[key];
    const addSetting= (key, data) => { 
        setting_data[key]=data;    
        if (key==='cp') setSelectCP(data)
    }
    const resetSetting= () => {
        deleteLocal('cp');
        deleteLocal('address');
        deleteLocal('payment');
        deleteLocal('shipping');
        setting_data.length = 0;
    }

    /* ---------- Custom Operations -------------- */    
    const getLine = (top, left, size, color) => { return {top, left, size, color, value:""}};
    const getImage = (top, left, size) =>  { return {top, left, size, value:''}};
    const empty_custom = (color, linesCount=0, imagesCount=0) => {
        const lines = [];
        const images = [];
        for (let i = 0; i < linesCount; i++) {
            lines.push(getLine(150+(i*32),100,100, color));        
        }
        for (let i = 0; i < imagesCount; i++) {
            images.push(getImage(150+(i*100),0,100));        
        }
        return {lines, images};
    }
    
    const  [cartItems, setCartItems] = useState(getLocalCart());
    const [select_cp, setSelectCP] = useState(getSetting('cp'))
    const contextValue = {getTotalCartItems, getTotalCartAmount, getTotalWeight,
        getSetting, addSetting, empty_custom, resetSetting, getShippingAmount,
        cartItems, addToCart, removeFromCart, updateCart, select_cp};

    return (
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    )
}


export default ShopContextProvider;