import React from 'react'
import './ImageList.css'
import { coleccion_imagen } from '../../../Apis/Coleccion';

const ImageList = ({image, onClick, selected}) => {
    const clase = selected && image===selected ? "imagelist w-100 imagelist-selected" :"imagelist w-100"

  return (
    <div className={clase}>
        <img onClick={onClick} src={image} alt="" />
    </div>
  )
}

export default ImageList
