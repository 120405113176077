import React, { useState } from 'react'
import './CSS/Checkout.css'
import ShippingToList from '../Components/Order/Address/ShippingToList'
import Order from '../Components/Order/Order'
import ShippingWith from '../Components/Order/Shipping/ShippingWith'
import PaymentWith from '../Components/Order/Payment/PaymentWith'
import TotalList from '../Components/Order/Totales/TotalList'
import ItemList from '../Components/Order/Items/ItemList'
import { AuthRequest } from '../Apis/Axios'
import { getLocal, saveLocal } from '../Apis/Api'
import useShop from '../Hooks/useShop'
import Message from '../Components/Message/Message'
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'

const Checkout = () => {
  const [msg, setMsg] = useState("")
  const [paying, setPaying] = useState(false)
  const [preference, setPreference] = useState(null)
  const {getTotalCartAmount, cartItems, updateCart} = useShop()
  const [shipping, setShipping] = useState(getLocal('shipping'))
  const hdlSave = async () => {
    const payment = getLocal('payment')
    const address = getLocal('address')
    const obs = getLocal('observaciones')
    const tienda = getLocal('tienda')

    if (!getTotalCartAmount()) {
      setMsg("El carrito esta vacío")
      return null
    }
    if (!payment || !shipping || !address) {
      setMsg("Falta información de pago, envío o entrega")
      return null
    }
    setPaying(true)
    const res = AuthRequest()
    const detalle = []
    for (const i of cartItems) {
      detalle.push({stock_id:i.stock_id, custom:i.custom, cantidad:i.cantidad})
    }

    const body = {
      address_id:address.id,
      payment_id:payment.id,
      shipping_id:shipping.id,
      observaciones:obs,
      detalle:detalle
    }

    const r = await res.post(`pedido/${tienda}`, { body })
    // console.log("Submit:", r);
    // console.log("Payment:", payment.valor);
    
    if (r.ok) {
      updateCart([]);
      if (payment.clave.substring(0,7)==='mercado') {
        setPreference(r.data?.preference?.response?.id)
        initMercadoPago(payment.valor.public, { locale: "es-MX" });
      } else window.location = `/order/${r.data.uuid}`;
    }
    setPaying(false)
  }

  const hdlShippíng = (data) => {
    saveLocal('shipping', data)
    setShipping(data)
  }


  return (
    <div className='checkout row'>
      <div className="row"><h1>Procesar Pago</h1></div>
      <div className="row">
        <div className="col-6">
          <ShippingToList />
        </div>
        <div className="col-6"><ShippingWith onSelect={hdlShippíng} /></div>
      </div>
      <div className="row">
        <div className="col-6"><PaymentWith  /></div>
        <div className="col-6"><Order/></div>
      </div>
      <div className="row">
        <div className="col-6"><ItemList /></div>
        <div className="col-6">
          <TotalList paying={paying} shipping={shipping} onSave={hdlSave}  />
          <Wallet initialization={{ preferenceId: preference }} 
                  customization={{ texts:{ valueProp: 'smart_option'}}} />
        </div>
      </div>      
      <div className="row">
          <Message type='error' mensaje={msg} />
      </div>
    </div>
  )
}

export default Checkout
