import React, { useEffect, useState }  from 'react'
import './CartItems.css'
import remove_icon from '../Assets/cart_cross_icon.png';
import useShop from '../../Hooks/useShop';
import { Link } from 'react-router-dom';
import { empty_cart, fetch_cart, getLocal, post_cart, saveLocal } from '../../Apis/Api';
import Message from '../Message/Message';
import EntryMsg from '../EntryMsg/EntryMsg';
import { asMoney } from '../Order/Totales/Total';

const CartItems = ({onQuote, payments, shippings}) => {
    const [msg, setMsg] = useState("")
    const [cp, setCp] = useState(getLocal('cp'))
    const [envio, setEnvio] = useState(0)
    const [shipping, setShipping] = useState(-1)
    const {getTotalCartAmount, getTotalWeight, cartItems, 
        addSetting, getSetting, getShippingAmount,
        updateCart,  removeFromCart, addToCart} = useShop();
    const quote = {"-1":0}
    const fetch_data = async () => {
        const auth = getLocal('auth')
        if (!auth?.x_token) {
            const res = await fetch_cart()
            if (!res.ok) setMsg(res.data)
        } else {
            await empty_cart()
        }
        const items = []
        for (const c of cartItems) {
            items.push({stock_id:c.stock_id, cantidad:c.cantidad, custom:JSON.stringify(c.custom)})
        }
        const data = await post_cart(items)
        if (data.ok)  updateCart(data.data)
        else setMsg(data.data)
    }

    useEffect(()=>{
        fetch_data();
    },[])

    const hdlCP = (e) => setCp(parseInt(e.target.value))
    const hdlQuote = async () =>  {
        saveLocal('cp',Number(cp));
        await onQuote(Number(cp))
        setEnvio(0)
        setShipping(-1)
    }
    const hdlShipping = (e) => {
        const idx = e.target.value
        const amount = parseFloat(quote[idx])
        setEnvio(amount)
        setShipping(idx)
        addSetting('shipping-amount', amount)
        addSetting('shipping-id', idx)
    }

    const showShipping=() => {
        if (!shippings) return <></>
        const all = shippings.map((e, i)=> {
            quote[e.id] = getShippingAmount(e)
            return <option key={i} value={e.id}>{e.valor.nombre}  ${asMoney(quote[e.id])}</option>
        })
        return all;
    }

  const show=(e) => {
    if (!e.custom || !e.custom?.mapa) return <></>
    const todos = []
    for (const m in e.custom.mapa) {
        for (const q of e.custom.mapa[m]) {
            todos.push(q)
        }
    }

    return (<span className='cartitems-custom-box'>
        {
            todos.map((x,y)=> {
                if (!x.url) return null
                if (x.url.startsWith('http'))
                    return  <span key={y} className='cartitems-custom'>
                        <span style={{fontSize:"0.8rem"}}>{x.nombre}<br/></span> 
                        <img src={x.url} alt="" width="45px" />
                    </span>
                return <span key={y} className='cartitems-custom'>
                        <span style={{fontSize:"0.8rem"}}>{x.nombre} : </span><br/>
                        <span style={{fontSize:"0.8rem"}}>{x.url}</span> 
                    </span>
            })
            }
        </span>)
  }


  return (
    <div className='cartitems col-12'>
      <div className="row"><Message tipo="error" msg={msg} /></div>
      <div className="cartitems-format-main row">
        <p className='w-5 hide-sm'></p>
        <p className='w-10 hide-sm'>Producto</p>
        <p className='w-15 only-sm'>Producto</p>
        <p className='w-40'>Descripción</p>
        <p className='w-10 aright'>Precio</p>
        <p className='w-10 aright'>Cant</p>
        <p className='w-10 aright'>Total</p>
        <p className='w-5'>Eliminar</p>
        <hr />
      </div>
        {cartItems.map((e, idx)=>{
            return (
              <div key={idx} className='col-12'>
                <div className="cartitems-format cartitems-format-main row">
                    <p className='hide-sm w-5'></p>
                    <p className='w-10 hide-sm'><img src={e.imagen} alt="" className="cartitems-product-icon" /></p>
                    <p className='w-15 only-sm'><img src={e.imagen} alt="" className="cartitems-product-icon" /></p>
                    <p className='w-40'>{e.nombre} / {e.talla} / {e.sku}
                    {show(e)}
                    </p>
                    <p className='w-10 aright'>${asMoney(e.oferta)}</p>
                    <p className='w-10 aright'><button onClick={()=>addToCart(e)} className='cartitems-quantity'>{e.cantidad}</button></p>
                    <p className='w-10 aright'>${asMoney(e.oferta*e.cantidad)}</p>
                    <p className='w-5'><img className='cartitems-remove-icon' src={remove_icon} onClick={()=>removeFromCart(e)} alt="" /></p>
                </div>
                <hr />                    
              </div>      
            )
        })}
        <div   className="cartitems-down row">
            <div className="cartitems-total col-5">
                <div className="w-12">
                    <h1>Totales</h1>
                </div>
                <div className="cartitems-total-item row">
                    <p className='w-50'>Peso</p>
                    <p className='w-50'>{asMoney(getTotalWeight())}</p>
                </div>
                <hr />
                <div className="cartitems-total-item row">
                    <p className='w-50'>Subtotal</p>
                    <p className='w-50'>${asMoney(getTotalCartAmount())}</p>
                </div>
                <hr />

                <div className="cartitems-total-item row">
                    <div className="w-100">
                        <select name="shipping" value={shipping} onChange={hdlShipping} >
                        <option  key={-1} value={-1}>Seleccione una opción</option>
                            { showShipping() }
                        </select>
                    </div>
                    <div className="w-50">
                        <EntryMsg label="CP" length={5} value={cp} change={hdlCP} name="cp" holder="código postal" />
                    </div>
                    <div className='w-50'>
                        <button onClick={hdlQuote} className="btn">Cotizar</button>
                    </div>
                </div>

                <div className="cartitems-total-item row">
                    <p  className='w-50'>Envío</p>
                    <p  className='w-50'>${envio? asMoney(envio):'N/D'}</p>
                </div>
                <hr />
                <div className="cartitems-total-item row">
                    <p  className='w-50'>Total</p>
                    <p  className='w-50'>${asMoney(getTotalCartAmount()+envio)}</p>
                </div>

                <Link to='/checkout'>
                    <button>Pagar</button>
                </Link>
            </div>            
            <div className="cartitems-total col-2"></div>
            <div className="cartitems-promocode col-5">
                <h1>Introduzca su cupón aquí</h1>
                <div className="cartitems-promobox">
                    <input type="text" placeholder='Código' />
                    <button>Submit</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CartItems
