import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import './DominiosList.css'
import { AuthRequest } from '../../Apis/Axios';
import Dominio from './Dominio';
import Message from '../../Components/Message/Message';
import EntryMsg from '../../Components/EntryMsg/EntryMsg';

const DominiosList = () => {
const {tiendaId} = useParams();
const [lista, setLista] = useState([])
const [tienda, setTienda] = useState({})
const [msg, setMsg] = useState('')
const [dominio, setDominio] = useState('https://')

const fetch_data = async () => {
    const r = AuthRequest()
    const res = await r.fetch(`tienda/${tiendaId}/dominio`)
    if (res.ok) {
        setLista(res.data.rows)
    } else  { setLista([])}
}

const fetch_tienda = async () => {
    const r = AuthRequest()
    const res = await r.fetch(`tienda/${tiendaId}`)
    if (res.ok) {
        setTienda(res.data)
    } else  { setTienda({})}
}

useEffect(()=>{
    fetch_data();
    fetch_tienda();
},[tiendaId])

  const hdlRemove = async (id) => {
    const res = AuthRequest()
    const url = `tienda/${tiendaId}/dominio/${id}`
    const r = await res.delete(url)
    if (r.ok) {
        fetch_data()
    } else setMsg(r.data)
  }

  const hdlChange = (e) => setDominio(e.target.value)

  const hdlAdd = async () => {
    const res = AuthRequest()
    const url = `tienda/${tiendaId}/dominio`
    const body = {nombre:dominio, logo:''}
    const r = await res.post(url, {body})
    if (r.ok) {
        setDominio('https://')
        fetch_data()
    } else {
        setMsg(r.data)
    }
  }

  return (
    <div className='dominioslist row'>        
        <div className="container col-12">
            <h1>Lista de Dominios {tienda?.nombre} </h1>            
            <Message tipo="error" mensaje={msg} />
            <div className="row container-add">
                    <EntryMsg value={dominio} change={hdlChange} length={100} name='dominio' />
                    <button onClick={hdlAdd} >Agregar</button>
            </div>
            <div className="row">
                { lista.map((e,i)=>{
                    return <Dominio onRemove={hdlRemove} props={e} key={i} />
                  })
                }
            </div>
        </div>
    </div>
  )
}


export default DominiosList
