import React from 'react'
import './Offers.css'
import exclusive_image from '../Assets/exclusive_image.png'

const Offers = () => {
  return (
    <div className='offers row'>
      <div className="offers-left col-6">
        <h1>Ofertas</h1>
        <h1>Exclusivas</h1>
        <p>Solamente de lo más vendido</p>
        <button>Ver Ahora</button>
      </div>
      <div className="offers-right col-5 hide-sm">
        <img src={exclusive_image} alt="" />
      </div>
    </div>
  )
}

export default Offers
