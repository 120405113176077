import React, { useEffect, useState } from 'react'
import './PaymentList.css'
import Payment from './Payment'
import { AuthRequest } from '../../Apis/Axios'
import useAuth from '../../Hooks/useAuth'
import { forbidden } from '../../Components/RequireAuth/RequireStaff'
import PaymentForm from './PaymentForm'
import { Link } from 'react-router-dom'

const PaymentList = () => {
    const {getStore, getStoreName} = useAuth()
    const [store, setStore] = useState(getStore())        
    const [msg,setMsg] = useState('')
    const [lista, setLista] = useState([])
    const [edit, setEdit] = useState(0)

    const fetch_data = async ()=>{    
        const res = AuthRequest()
        const params = {where:"grupo:eq(pagos)", sort_by:"id desc"}
        const url= `utils/setting/${store.id}`
        const r = await res.fetch(url, {params})
        if (r.ok) {
            setLista(r.data.rows)
        } else setMsg(r.data)
     }
    
    useEffect(()=>{
        fetch_data()
    },[])

    const hdlEdit = (elem) => setEdit(elem)
    const hdlRefresh = () => {
        setEdit(0);
        fetch_data();
    }
    const hdlCancel = () => {setEdit(0)}
    const hdlNew = () => {
        const valor = { "nombre":"", "descripcion":"",
        "logo":"", "public":"", "access_token":"", "web_hook":"", "status_code":"" }
        
        const p = {id:0, grupo:'pagos', clave:'',  valor:JSON.stringify(valor)}
        setEdit(p)
    }
    const show = () => {
        if (!edit) return <></>
        return <PaymentForm onRefresh={hdlRefresh} props={edit} />
    }
    if (!store) 
    return forbidden("No ha seleccionado la tienda","Es necesario seleccionar una tienda para xontinuar","/admin")

  return (
    <div className='paymentlist row'>
        <h1>Métodos de Pago {getStoreName()}</h1>
        <button onClick={hdlCancel} className='btn flex-right' >Cancelar</button>
        <button onClick={hdlNew} className='btn flex-right' >Agregar</button>
        {show()}
        <div className="paymentlist-container row">
        {
            lista.map((elem, idx)=>{
                return <Payment onEdit={hdlEdit}  props={elem} key={idx}/>
            })
        }
        </div>
        <Link to="/admin">Volver Administrador</Link>
    </div>
  )
}

export default PaymentList
