import React, { useEffect, useState } from 'react'
import './ShippingList.css'
import Shipping from './Shipping'
import { AuthRequest } from '../../Apis/Axios'
import useAuth from '../../Hooks/useAuth'
import { forbidden } from '../../Components/RequireAuth/RequireStaff'
import ShippingForm from './ShippingForm'
import { Link } from 'react-router-dom'

const ShippingList = () => {
    const {getStore, getStoreName} = useAuth()
    const [store, setStore] = useState(getStore())        
    const [msg,setMsg] = useState('')
    const [lista, setLista] = useState([])
    const [edit, setEdit] = useState(0)

    const fetch_data = async ()=>{    
        const res = AuthRequest()
        const params = {where:"grupo:eq(envios)", sort_by:"id desc"}
        const url= `utils/setting/${store.id}`
        const r = await res.fetch(url, {params})
        if (r.ok) {
            setLista(r.data.rows)
        } else setMsg(r.data)
     }
    

    useEffect(()=>{
        fetch_data()
    },[])

    const hdlEdit = (elem) => setEdit(elem)
    const hdlRefresh = () => {
        setEdit(0);
        fetch_data();
    }
    const hdlCancel = () => {setEdit(0)}
    const hdlNew = () => {
        const valor = {
            "nombre":"", "descripcion":"", "hasta":0,
            "precio":0, "adicional":{"precio":0, "ampara":0},
            "gratis": 0, "zona":{"municipios":["*"],"excepto":[]}
        }
        const p = {id:0, grupo:'envios', clave:'',  valor:JSON.stringify(valor)}
        setEdit(p)
    }
    const show = () => {
        if (!edit) return <></>
        return <ShippingForm onRefresh={hdlRefresh} props={edit} />
    }
    if (!store) 
    return forbidden("No ha seleccionado la tienda","Es necesario seleccionar una tienda para xontinuar","/admin")

  return (
    <div className='shippinglist row'>
        <h1>Métodos de Envio {getStoreName()}</h1>
        <button onClick={hdlCancel} className='btn flex-right' >Cancelar</button>
        <button onClick={hdlNew} className='btn flex-right' >Agregar</button>
        {show()}
        <div className="shippinglist-container row">
        {
            lista.map((elem, idx)=>{
                return <Shipping onEdit={hdlEdit}  props={elem} key={idx}/>
            })
        }
        </div>
        <Link to="/admin">Volver Administrador</Link>
    </div>
  )
}

export default ShippingList
