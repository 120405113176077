import React from 'react'
import { asMoney } from '../Order/Totales/Total'
import './OrderDetail.css'

const OrderDetail = ({data}) => {
  const p = {...data}
  return (
    <div className='orderdetail row'>
      <div className="orderdetail-format row bold">
        <p className='w-5 hide-sm'></p>
        <p className='w-15'>Producto</p>
        <p className='w-50'>Descripción</p>
        <p className='w-10 aright'>Precio</p>
        <p className='w-10 aright'>Cant</p>
        <p className='w-10 aright'>Total</p>
      </div>
        <hr />
        {p.detalle.map((e, idx)=>{
            return (
              <div key={idx} className='col-12'>
                <div className="orderdetail-format row">
                    <p className='hide-sm w-5'></p>
                    <p className='w-15'><img src={e.imagen} alt="" className="cartitems-product-icon" /></p>
                    <p className='w-50'>{e.descripcion} / {e.talla} / {e.sku}</p>
                    <p className='w-10 aright'>${asMoney(e.precio)}</p>
                    <p className='w-10 aright'>{e.cantidad}</p>
                    <p className='w-10 aright'>${asMoney(e.precio*e.cantidad)}</p>
                </div>
                <hr />                    
              </div>      
            )
        })}

    </div>
  )
}

export default OrderDetail
