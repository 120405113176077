import React, { useState } from 'react'
import './TiendasList.css'
import EntryMsg from '../../Components/EntryMsg/EntryMsg'
import Tienda from './Tienda'
import { AuthRequest } from '../../Apis/Axios'
import Message from '../../Components/Message/Message'
import { Link } from 'react-router-dom'

const TiendasList = () => {
 const [filtro, setFiltro] = useState("")
 const [tienda, setTienda] = useState("")
 const [codigo, setCodigo] = useState("")
 const [msg, setMsg] = useState("")
 const [tiendas, setTiendas] = useState([])
 const [offset, setOffset] = useState(0)

 const hdlFiltro = (evt)  =>   setFiltro(evt.target.value)   
 const hdlTienda = (evt)  =>   setTienda(evt.target.value)   
 const hdlCodigo = (evt)  =>   setCodigo(evt.target.value)   
 
 const hdlBuscar = async ()=>{    
    const res = AuthRequest()
    const params = {enabled:0, limit:30, offset, sort_by:"nombre"}
    if (filtro) params['where'] = `nombre:like(${tienda})`
    const r = await res.fetch("tienda", {params})
    if (r.ok) {
        setTiendas(r.data.rows)
    } else setMsg(r.data)
 }

 const hdlAdd = async () => {
    if (!tienda) {
        setMsg("Se requieree una tienda")
        return
    }
    const res = AuthRequest()
    const body = {nombre:tienda, codigo}
    const r = await res.post('tienda', {body})
    if (r.ok) {
        tiendas.push(r.data)
        setTiendas([...tiendas])
    } else setMsg(r.data)
 }

  return (
    <div className='tiendaslist row'>
        <div className="row">
            <div className="col-3"></div>
            <div className="col-6">
                <Message tipo="error" mensaje={msg} />
            </div>
        </div>
        <div className="tiendaslist-container col-6">
            <h1>Tiendas Registradas</h1>
            <div className='tiendaslist-filtro'>
                <span>Filtrar:</span>
                <input type="text" onChange={hdlFiltro} value={filtro}  />
                <button onClick={hdlBuscar}>Buscar</button>
            </div>
        </div>
        <div className="tiendaslist-container tiendaslist-add col-6">
            <h1>Nueva Tienda</h1>
            <EntryMsg name="tienda" msg={msg} length={100} value={tienda} required change={hdlTienda} />
            <EntryMsg name="codigo" msg={msg} length={10} value={codigo} change={hdlCodigo} />
            <button onClick={hdlAdd}>Agregar</button>

        </div>
        <hr/>
      <div className="row">
        { tiendas.map((elem, idx)=>{
            return <Tienda search={hdlBuscar} key={idx} props={elem} />
        })}
      </div>
      <Link to="/admin">Volver Administrador</Link>
    </div>
  )
}

export default TiendasList
