import React, { useEffect, useState } from 'react'
import './AccountInfo.css'
import AddressList from '../AddressList/AddressList';
import User from '../User/User';
import { AuthRequest } from '../../Apis/Axios';
import useAuth from '../../Hooks/useAuth';
import OrderList from '../OrderInfo/OrderList';

const AccountInfo = (props) => {
    const Auth = useAuth()
    const [lista, setLista] = useState([]);
    const me = Auth.getAuth()?.user

    const fetch_data = async () => {
      const r = AuthRequest()
      const res = await r.fetch('usuario/direccion')
      if (res.ok) {
          setLista(res.data.rows)
      }else  { setLista(...[])}
    }

    useEffect(()=>{
        fetch_data();
      },[]);  

   const handleUpdLista = ()=> {
      fetch_data()
   }

  return (
    <div className='accountinfo row'>
        <User me={me} />
        <AddressList onUpdate={handleUpdLista} lista={lista} />
        <OrderList me={me} />
    </div>
  )
}

export default AccountInfo
