import React, { useEffect, useState } from 'react'
import Collection from './Collection'
import './CollectionList.css'
import { AuthRequest } from '../../Apis/Axios'
import useAuth from '../../Hooks/useAuth'
import { forbidden } from '../../Components/RequireAuth/RequireStaff'
import { Link } from 'react-router-dom'
import EntryMsg from '../../Components/EntryMsg/EntryMsg'

const CollectionList = () => {
  const {getStore, getStoreName} = useAuth()
  const [store, setStore] = useState(getStore())        
  const [lista, setLista] = useState([])
  const [filtro, setFiltro] = useState("")
  const [msg, setMsg] = useState("")

  const fetch_data = async (id)=>{    
      const res = AuthRequest()
      const params = filtro ? {where:`coleccion:like(${filtro});`} : {}
      const url = `utils/coleccion/${id}`
      const r = await res.fetch(url, {params})
      if (r.ok) {
          setLista(r.data.rows)
      } else setMsg(r.data)
   }

   useEffect(()=>{
      if (store) fetch_data(store.id)
  },[])

const hdlSearch = () => fetch_data(store.id);
const hdlFiltro = (e) => setFiltro(e.target.value);
const hdlDelete = async () => fetch_data(store.id)

  if (!store) 
   return forbidden("No ha seleccionado la tienda","Es necesario seleccionar una tienda para xontinuar","/admin")


  return (
    <div className='collectionlist row'>
      <h1>Lista de Colecciones de {getStoreName()}</h1>      
      <div className="row">
            <div className="collectionlist-search">
                <label>Colección:</label>
                <EntryMsg name="filtro" value={filtro} holder="Nombre de la colección" 
                    change={hdlFiltro} mensaje={msg} />
                <button onClick={hdlSearch} className='btn' >Filtrar</button>
                &nbsp;&nbsp;
                <Link to="/admin/collection/0">
                  <button className='btn' >Agregar</button>
                </Link>
            </div>
        </div>

      <div className="collectionlist-container row">
          {
              lista.map((elem,idx) => {
                  return <Collection store={store} onDelete={hdlDelete} props={elem} key={idx} />
              })
          }
      </div>
      <Link to="/admin">Volver Administrador</Link>
    </div>
  )
}

export default CollectionList
