import img_trash from '../Components/Assets/trash.png';
import { PublicRequest, SessionRequest, AuthRequest } from './Axios';

export const all_images = () => getLocal('p-imagenes')

const config = {};

async function fetch_it(url){
    const r = PublicRequest()
    const res = await r.fetch(url)
    return res.data;
}

export async function get_collection(key) {
    return await fetch_it('coleccion/'+key);
}

export async function get_item(id)  {
    return await fetch_it(`item/${id}`);
}

export async function get_customize(id)  {
    return await fetch_it(`personalizar/${id}`);
}


export async function  fetch_prod(store, item, onError)  {
    const res = AuthRequest();
    const url = `producto/${store}/${item}`;
    const r = await res.fetch(url);
    if (r.ok) return r.data
    else onError(r.data);
    return null;
  }


async function get_data(key) {
    if (config[key])  return config[key];
    const value = await fetch_it(key);
    config[key] = value
    return value;
}

async function  Settings(key) {
    return await get_data(key);
}

//  -------------------- Cart operations --------------------------

export const  fetch_cart = async () => {
    const r = SessionRequest()
    const res = await r.fetch('cart')
    if (res.ok) {
        const auth = getLocal("auth")
        if (!auth || !auth?.x_token) {
            const nuevo = {x_token: res.data['X-Token']}
            saveLocal("auth", nuevo)
        } 
    } 
    return res;
}

export const empty_cart = async () => {
    const r = SessionRequest()
    return await r.delete('cart')
}

export const put_cart = async (key, code) => {
    const r = SessionRequest()
    const body = {key, code}
    return await r.put('cart',{body})
}

export const post_cart = async (items) => {
    const r = SessionRequest()
    const body = items
    return await r.post('cart',{body})
}

export const fetch_shipping = async (cp)=>{    
    if (!cp) return []
    const res = PublicRequest()    
    const q = await res.fetch('cart/envios', {params:{cp:Number(cp)}})
    if (q.ok) {
        const tmp=[]
        for (let i = 0; i < q.data.length; i++) {        
            const row = q.data[i]   
            row.valor = JSON.parse(row.valor)
            tmp.push(row)
        }
        return tmp
    } 
    return []
  }

export const fetch_payment = async ()=>{    
    const res = PublicRequest()
    const r = await res.fetch('cart/pagos')
    if (r.ok) {
      const tmp=[]
      for (let i = 0; i < r.data.length; i++) {        
        const row = r.data[i]   
        row.valor = JSON.parse(row.valor)
        tmp.push(row)
      }
      return tmp
    }
    return []
 }

export const saveLocal = (key, obj) => localStorage.setItem(key, JSON.stringify(obj))
export const deleteLocal = (key) => localStorage.removeItem(key)
export const getLocal = (key) => {
    const data = localStorage.getItem(key);
    if (!data) return '';
    return JSON.parse(data);
}

export const pad = (num, size) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export const asUTC = (fecha) => {
    var date = new Date(fecha);
    var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                    date.getUTCDate(), date.getUTCHours(),
                    date.getUTCMinutes(), date.getUTCSeconds());
    
    let str = (new Date(now_utc)).toISOString();
    str = str.replace('T',' ').substring(0,19)
    return str
}

export const asLocal = (fecha)  => {
    if (!fecha) return '';
    var date = new Date(`${fecha.replace('T',' ')} UTC`);
    let str = date.toLocaleString();    
    return str
}

export const STATUS = ["Abierto","Enviado","Entregado","Cancelado","Pendiente","Rechazado"];

export default Settings
