import React, { useState } from 'react'
import './Order.css'
import { getLocal, saveLocal } from '../../Apis/Api'

const Order = () => {
    const [obs, setObs] = useState(getLocal('observaciones'))

    const hdlChange = (evt) => {
        setObs(evt.target.value)
        saveLocal('observaciones', evt.target.value)
    }

  return (
    <div className='order row'>
        <label htmlFor="observaciones">Observaciones</label>
        <textarea onChange={hdlChange} name="observaciones" value={obs} cols="50" rows="4"></textarea>
    </div>
  )
}

export default Order
