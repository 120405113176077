import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { PublicRequest } from '../../Apis/Axios';
import Message from '../Message/Message';
import './Page.css'

const Page = () => {
const {pageId} = useParams();
const [msg, setMsg] = useState("")
const [data, setData] = useState({})

const fetch_data = async () => {
    const res = PublicRequest()
    const url = `pagina/${pageId}`
    const r = await res.fetch(url)
    if (r.ok) setData(r.data)
    else setMsg(r.data)
}

useEffect(()=>{
    fetch_data();
},[pageId])


  return (
    <div className='page row'>
        <h1>{data?.pagina}</h1>            
        <div className="page-container flex-center">
            <Message tipo="error" mensaje={msg} />
            <div dangerouslySetInnerHTML={{__html: data?.contenido}} />
        </div>     
    </div>
  )
}

export default Page
