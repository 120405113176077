import React, { useState, useEffect } from 'react'
import './CSS/ShopCategory.css'
import Item from '../Components/Item/Item'
import { get_collection } from '../Apis/Api'

const ShopCategory = (props) => {
  const [data_product, setItems] = useState([]);
  useEffect(()=>{
    const fetch_data = async () => {
        const data= await get_collection(props.category);     
        if (data) setItems(data);
    }
    fetch_data();
  },[props.category]);

  return (
    <div className='shop-category row'>
      <div className="col-12">
        <img className='shopcategory-banner' src={props.banner} alt="" />      
        <hr />
        {/* <div className="shopcategory-index-sort">
          <div className="shopcategory-sort">
            Sort by <img src={dropdown} alt="" />
          </div>
        </div> */}
      </div>
      <div className="shopcategory-products row">
        {data_product.map((item, i) => {
          return <Item key={i} data={item} />
        })}
      </div>
    </div>
  )
}

export default ShopCategory
