import React, { useState } from 'react'
import './TotalList.css'
import useShop from '../../../Hooks/useShop'
import { getLocal } from '../../../Apis/Api'
import Total from './Total'

const TotalList = ({onSave, paying, shipping}) => {
  const {getTotalCartAmount, getShippingAmount} = useShop();
  const [total, setTotal] = useState(getTotalCartAmount()+getShippingAmount(shipping))

  return (
    <div className='totallist row'>
      <div className="totallist-container col-12">
        <Total amount = {getTotalCartAmount()} label="Subtotal:" />
        <Total amount = {getShippingAmount(shipping)} label="Envío:" />
        <Total amount = {total} label="A Pagar:" />
      </div>
      <div className="row">
        <div className="w-100" style={{textAlign:"right"}}>
          { paying ? <></>
            : <button id="btn-confirm" onClick={onSave} className='btn-lg'>Confirmar</button>
          }
          <div id="wallet_container"></div>
        </div>
      </div>
      
    </div>
  )
}

export default TotalList
