import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Shop from './Pages/Shop';
import ShopCategory from './Pages/ShopCategory';
import Product from './Pages/Product';
import LoginSignup from './Pages/LoginSignup';
import Cart from './Pages/Cart';
import Footer from './Components/Footer/Footer';
import men_banner from './Components/Assets/banner_mens.png'
import women_banner from './Components/Assets/banner_women.png'
import kids_banner from './Components/Assets/banner_kids.png'
import Personalizar from './Pages/Personalizar';
import Login from './Pages/Login';
import Account from './Pages/Account';
import Checkout from './Pages/Checkout';
import Admin from './Pages/Admin';
import RequireAuth  from './Components/RequireAuth/RequireAuth';
import RequireStaff  from './Components/RequireAuth/RequireStaff';
import Setting from './Restricted/Setting/Setting';
import TiendasList from './Restricted/Tiendas/TiendasList';
import DominiosList from './Restricted/Dominios/DominiosList'
import UserList from './Restricted/User/UserList';
import CustomerList from './Restricted/Customer/CustomerList';
import OrderList from './Restricted/Order/OrderList';
import ShippingList from './Restricted/Shipping/ShippingList';
import PaymentList from './Restricted/Payment/PaymentList';
import SectionList from './Restricted/Section/SectionList';
import Page from './Components/Page/Page';
import OrderInfo from './Components/OrderInfo/OrderInfo';
import CollectionList from './Restricted/Collection/CollectionList';
import CollectionForm from './Restricted/Collection/Form/CollectionForm';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Shop/>}/>
          <Route path='/playeras-y-polos' element={<ShopCategory banner={men_banner} category='playeras-y-polos' />}/>
          <Route path='/sudaderas-y-hoodies' element={<ShopCategory banner={women_banner} category='sudaderas-y-hoodies' />}/>
          <Route path='/accesorios' element={<ShopCategory banner={kids_banner} category='accesorios' />}/>
          <Route path='/product' element={<Product />}>
              <Route path=':productId' element={<Product />} />
          </Route>
          <Route path='/personalizar/:productId/:size' element={<Personalizar />} />
          <Route path='/cart' element={<Cart/>}/>
          <Route path='/login' element={<Login />}/>
          <Route path='/signup' element={<LoginSignup />}/>
          <Route path='/page/:pageId' element={<Page />} />

          <Route element={<RequireAuth />}>
            <Route path='/account' element={<Account />}/>
            <Route path='/checkout' element={<Checkout />}/>
            <Route path='/order/:orderId' element={<OrderInfo staff={false} />} />
          </Route>

          <Route element={<RequireStaff />}>
            <Route path='/admin' element={<Admin />}/>
            <Route path='/admin/setting' element={<Setting />}/>
            <Route path='/admin/collection' element={<CollectionList />}/>
            <Route path='/admin/collection/:collectionId' element={<CollectionForm />}/>
            <Route path='/admin/store' element={<TiendasList />}/>
            <Route path='/dominio/:tiendaId' element={<DominiosList />}/>
            <Route path='/admin/user' element={<UserList />}/>
            <Route path='/admin/customer' element={<CustomerList />}/>
            <Route path='/admin/orders' element={<OrderList />}/>
            <Route path='/admin/order/:orderId' element={<OrderInfo staff={true} />} />

            <Route path='/admin/shipping' element={<ShippingList />}/>
            <Route path='/admin/payment' element={<PaymentList />}/>
            <Route path='/admin/section' element={<SectionList />}/>

          </Route>


        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
