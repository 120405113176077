import React, {useEffect, useState} from 'react'
import './CollectionForm.css'
import { useParams } from 'react-router-dom';
import useAuth from '../../../Hooks/useAuth';
import Message from '../../../Components/Message/Message';
import { AuthRequest } from '../../../Apis/Axios';
import CollectionHeader from './CollectionHeader';
import CollectionContainer from './CollectionContainer';
import CollectionCanva from './CollectionCanva';
import CollectionSingle from './CollectionSingle';
import { Link } from 'react-router-dom';
import { coleccion, coleccion_item, coleccion_actual } from '../../../Apis/Coleccion';

const CollectionForm = () => {
  
  const {collectionId} = useParams();
  const {getStore, getStoreName} = useAuth()
  const [reload, setReload] = useState(false)
  const store = getStore()
  const [controlador, setControlador] = useState("")
  const [actual, setActual] = useState(coleccion_actual.value)
  const [selected, setSelected] = useState(coleccion.value?.custom?.keys??[])
  const [msg, setMsg] = useState("")
  
  const fetch_data = async (id) => {
    const res = AuthRequest();
    const url = `utils/coleccion/${id}/${collectionId}`
    const r = await res.fetch(url)    
    if (r.ok) {        
        const current = {
            coleccion:r.data.coleccion,
            enabled:r.data.enabled,
            handle:r.data.handle,
            etiquetas: r.data.etiquetas
        }        
        const c = JSON.parse(r.data.custom);
        current['custom'] = c
        coleccion.value = {...current}
        setControlador(r.data.controlador)
        if (r.data.controlador==='personalizar') setSelected([...c.keys])
        setReload(true)
    } else {
      setMsg(r.data);
    }
  }

  useEffect(()=>{
    if (parseInt(collectionId) && store) fetch_data(store.id)
    else {
      coleccion.value = { coleccion:"", etiquetas:"", enabled:1, id:0,
        custom:{ product_id:0, color:"red", border:"black", mapa:{}, keys:[] }
      }
      coleccion_item.value = {}
      coleccion_actual.value = 0
    }
  },[])


  const hError = (value, message) => {
    if (!value) {
      alert(message);
      setMsg(message)
      return false;
    }
    return true;
  }


  const hdlUpdate = async () => {  
    if (!hError(coleccion.value.coleccion, "Se requiere nombre de colección" )) return false;
    if (!hError(coleccion.value.etiquetas, "Se requiere etiquetas de colección")) return false;
    if (controlador==='personalizar') {
      if (!hError(coleccion.value.custom.color, "Se requiere color " )) return false;
      if (!hError(coleccion.value.custom.border, "Se requiere color de borde " )) return false;
      if (!hError(coleccion.value.custom.product_id, "Se requiere producto base " )) return false;
      if (!hError(coleccion.value.custom.keys.length, "Se requiere imágenes seleccionadas " )) return false;
      if (!hError(Object.keys(coleccion.value.custom.mapa).length, "Se seleccionar cuadrantes " )) return false;
    }

    const res = AuthRequest()
    const url = Number(collectionId) ? `utils/coleccion/${store.id}/${collectionId}` : `utils/coleccion/${store.id}`
    const body =  {
      controlador : controlador,
      coleccion: coleccion.value.coleccion,
      etiquetas: coleccion.value.etiquetas,
      custom: JSON.stringify(coleccion.value.custom)
    }
    let r = null;
    if (Number(collectionId)>0) r = await res.put(url, {body} )
    else r = await res.post(url, {body} )

    if (r.ok) {
      window.location.href = "/admin/collection";
    }  else setMsg(r.data)
  }


  if (!Number(parseInt(collectionId)) || controlador==='personalizar') 
      return (
        <div className='collectionform row'>
            <Message tipo="error" msg={msg} />
            <h1 className='flex-center'>Mantenimiento de Colecciones {getStoreName()}</h1>
            <p className='flex-right' ><Link to ='/admin/collection'>Volver a Colecciones</Link></p>

          <CollectionHeader onSelect={setSelected} onError={setMsg} reload={reload} store={store}  />
          <CollectionCanva  reload={reload} onSelect={setActual} selected={selected} actual={actual} />
          <CollectionContainer reload={reload} actual={actual} />
          <div className="row flex-center">
            <Link to='/admin/collection'><button  className='btn'>Cancelar</button></Link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={hdlUpdate} className='btn'>Actualizar</button>
          </div>
        </div>
      )

  return (
    <div className='collectionform row'>
        <Message tipo="error" msg={msg} />
        <h1 className='flex-center'>Mantenimiento de Colecciones {getStoreName()}</h1>
        <Link to='/admin/collection'><CollectionSingle  onUpdate={hdlUpdate} onError={setMsg} /></Link>
    </div>
  )
}

export default CollectionForm
