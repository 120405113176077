import React, {useEffect, useState} from 'react'
import './CSS/Admin.css'
import { Link } from 'react-router-dom'
import { saveLocal } from '../Apis/Api'
import SearchBar from '../Components/SearchBar/SearchBar'
import useAuth from '../Hooks/useAuth';

const Admin = () => {
    const {getStoreName} = useAuth()
    const [store, setStore] = useState(getStoreName())
    const params = {where:`$field:like($filtro)`, sort_by:"$field", limit:10}

    const hdlSelect = (data) => {
        saveLocal('store', data)
        setStore(data.nombre)
    }
 
  return (
    <div className='admin row'>
        <h1>Sección de Administración {store}</h1>
        <div className="row admin-header">
            <div className="col-12 admin-filtro">
                <SearchBar holder="Tienda" onSelect={hdlSelect} field="nombre" 
                    value={store} url="tienda"  params={params} />
            </div>
        </div>

        <div className="admin-container col-3">
            <h2>Settings</h2>
            <ul>
                <li><Link to='/admin/setting'>Configuración</Link></li>
                <li><Link to='/admin/store'>Tienda</Link></li>
                <li><Link to='/admin/user'>Usuarios</Link></li>
                <li><Link to='/admin/customer'>Clientes</Link></li>
                <li><Link to='/admin/orders'>Pedidos</Link></li>
            </ul>
        </div>
        <div className="admin-container col-3">
            <h2>Plugins</h2>
            <ul>
                <li><Link to='/admin/shipping'>Envíos</Link></li>
                <li><Link to='/admin/payment'>Pagos</Link></li>
                <li><Link to='/admin/collection'>Colecciones</Link></li>
                <li><Link to='/admin/section'>Secciones</Link></li>
            </ul>
        </div>
        
    </div>
  )
}

export default Admin
