import React, { useState } from 'react'
import './SectionForm.css'
import EntryMsg from '../../Components/EntryMsg/EntryMsg';
import Message from '../../Components/Message/Message';
import { AuthRequest } from '../../Apis/Axios';
import useAuth from '../../Hooks/useAuth';
import { forbidden } from '../../Components/RequireAuth/RequireStaff';

const SectionForm = ({onRefresh, props}) => {    
    const {getStore} = useAuth()
    const [label, setLabel] = useState(props.id?'Actualizar':'Agregar')
    const [msg, setMsg] = useState('')
    const [store, setStore] = useState(getStore())
    const [data, setData] = useState(props);

    const hdlChange = (e) => {
        setMsg("")
        const {name, value} = e.target;        
        data[name] = value
        setData({...data})
    }

    const hdlAdd = async () => {
        const res = AuthRequest()
        const url = `utils/pagina/${store.id}` 
        const urlp = `utils/pagina/${store.id}/${props?.id}`
        let r = null;
        props.pagina=data.pagina
        props.handle=data.handle
        props.contenido = data.contenido
        if (!props.id) {
            r = await res.post(url, {body:props})
        } else  r = await res.put(urlp, {body:props})
        if (r.ok) {
            onRefresh()
        } else setMsg(r.data)
    }

  if (!store) return forbidden("Se requiere la tienda", "Es necesario seleccionar una tienda para continuar","/admin")

  return (
    <div className='sectionform'>
        <div className="sectionform-container row">
        <EntryMsg msg={msg} change={hdlChange} name="pagina" value = {data.pagina} length={50} required label="Página"/>
        <EntryMsg msg={msg} change={hdlChange} name="handle" value = {data.handle} length={200} required label="Handle"/>
        <EntryMsg msg={msg} change={hdlChange} name="contenido" value = {data.contenido} length={20000} required 
          type="area" cols={100}  label="Contenido"/>
        </div>
        <div className="flex-center">
          <button onClick={hdlAdd} className='btn-lg'>{label}</button>
            <Message msg={msg} tipo="error" />
        </div>
        <hr />
    </div>
  )
}

export default SectionForm
